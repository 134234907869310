import {constantRoutes} from '@/router'
import {getRouters} from '@/api/route'
import Layout from '@/layout/index'
import ParentView from '@/components/ParentView'
import InnerLink from '@/layout/components/InnerLink'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
console.log('+++++++++++++++++', constantRoutes)
const permission = {
    state: {
        routes: [],
        addRoutes: [],
        defaultRoutes: [],
        topbarRouters: [],
        sidebarRouters: constantRoutes,
        siteConfig: {},
    },
    mutations: {
        SET_ROUTES: (state, routes) => {
            state.addRoutes = routes
            state.routes = constantRoutes.concat(routes)
        },
        SET_DEFAULT_ROUTES: (state, routes) => {
            state.defaultRoutes = constantRoutes.concat(routes)
        },
        SET_TOPBAR_ROUTES: (state, routes) => {
            // 顶部导航菜单默认添加控制台栏指向首页
            const index = [
                {
                    path: 'index',
                    meta: {title: '控制台', icon: 'dashboard'},
                },
            ]
            state.topbarRouters = routes.concat(index)
        },
        SET_SIDEBAR_ROUTERS: (state, routes) => {
            // state.sidebarRouters = routes
        },
        SET_SITE_CONFIG: (state, config) => {
            state.siteConfig = config
        },
    },
    actions: {
        // 生成路由
        GenerateRoutes({commit}) {
            return new Promise(resolve => {
                // 向后端请求路由数据
                // getRouters().then(response => {

                // })
                const menuInfo = []
                const sdata = JSON.parse(JSON.stringify(menuInfo || []))
                const rdata = JSON.parse(JSON.stringify(menuInfo || []))
                // const sidebarRoutes = filterAsyncRouter(sdata)
                // const rewriteRoutes = filterAsyncRouter(rdata, false, true)
                // rewriteRoutes.push({ path: '*', redirect: '/404', hidden: true })
                commit('SET_ROUTES', menuInfo || [])
                commit('SET_SIDEBAR_ROUTERS', menuInfo)
                commit('SET_DEFAULT_ROUTES', menuInfo)
                commit('SET_TOPBAR_ROUTES', menuInfo)
                // console.log('sidebarRoutes', state.sidebarRouters)
                // commit('SET_SITE_CONFIG', response.data.siteConfig)
                resolve(menuInfo || [])
            })
        },
    },
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
    return asyncRouterMap.filter(route => {
        if (type && route.children) {
            route.children = filterChildren(route.children)
        }
        if (route.component) {
            // Layout ParentView 组件特殊处理
            if (route.component === 'Layout') {
                route.component = Layout
            }
            //  else if (route.component === 'ParentView') {
            //     route.component = ParentView
            // } else if (route.component === 'InnerLink') {
            //     route.component = InnerLink
            // } else {
            //     route.component = loadView(route.component)
            // }
        }
        if (route.children != null && route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children, route, type)
        } else {
            delete route['children']
            delete route['redirect']
        }
        return true
    })
}

function filterChildren(childrenMap, lastRouter = false) {
    var children = []
    childrenMap.forEach((el, index) => {
        if (el.children && el.children.length) {
            if (el.component === 'ParentView') {
                el.children.forEach(c => {
                    c.path = el.path + '/' + c.path
                    if (c.children && c.children.length) {
                        children = children.concat(filterChildren(c.children, c))
                        return
                    }
                    children.push(c)
                })
                return
            }
        }
        if (lastRouter) {
            el.path = lastRouter.path + '/' + el.path
        }
        children = children.concat(el)
    })
    return children
}

export const loadView = view => {
    // 路由懒加载
    return resolve => require([`@/views/${view}`], resolve)
}

export default permission
