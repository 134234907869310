<template>
    <div class="sidebar-logo-container" :class="{ collapse: collapse }"
         :style="{ backgroundColor: sideTheme === 'theme-dark' ? variables.menuBg : variables.menuLightBg }">
        <transition name="sidebarLogoFade">
            <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/basic/info">
                <img v-if="logo" :src="logo" class="sidebar-logo" style="width: 50px; height: 50px"/>
                <h1 v-else class="sidebar-title"
                    :style="{ color: sideTheme === 'theme-dark' ? variables.sidebarTitle : variables.sidebarLightTitle }">
                    {{ title }}
                </h1>
            </router-link>
            <router-link v-else key="expand" class="sidebar-logo-link" to="/basic/info">
                <img v-if="logo" :src="logo" class="sidebar-logo"/>
                <h1 class="sidebar-title"
                    :style="{ color: sideTheme === 'theme-dark' ? variables.sidebarTitle : variables.sidebarLightTitle }">
                    {{ title }}
                </h1>
            </router-link>
        </transition>
    </div>
</template>

<script>
import variables from '@/assets/styles/variables.scss'

export default {
    name: 'SidebarLogo',
    props: {
        collapse: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        variables() {
            return variables
        },
        sideTheme() {
            return this.$store.state.settings.sideTheme
        },
    },
    data() {
        return {
            title: this.$store.state.permission.siteConfig.site_title || '咪婴培训管理系统',
            logo: this.$store.state.permission.siteConfig.site_logo || require('@/assets/logo/logo.png'),
        }
    },
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
    transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
    opacity: 0;
}

.sidebar-logo-container {
    position: relative;
    width: 100%;
    //   height: 150px;
    //   line-height: 50px;
    background: #2b2f3a;
    text-align: center;
    overflow: hidden;

    & .sidebar-logo-link {
        height: 100%;
        width: 100%;

        & .sidebar-logo {
            width: 60px;
            height: 60px;
            //   vertical-align: middle;
            //   margin-right: 12px;
            display: block;
            margin: 5px auto;
        }

        & .sidebar-title {
            display: inline-block;
            margin: 0;
            color: #fff;
            font-weight: 600;
            font-size: 14px;
            font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
            vertical-align: middle;
        }
    }

    &.collapse {
        .sidebar-logo {
            margin-right: 0px;
        }
    }
}
</style>
