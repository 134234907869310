<template>
    <div class="navbar">
        <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
                   @toggleClick="toggleSideBar"/>

        <i class="el-icon-refresh hamburger-container text-large" style="padding: 0 5px; margin: 2px 0 0 0"
           @click="refreshSelectedTag"></i>

        <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!topNav"/>
        <top-nav id="topmenu-container" class="topmenu-container" v-if="topNav"/>

        <div class="right-menu">
            <template v-if="device !== 'mobile'">
                <search id="header-search" class="right-menu-item"/>

                <!-- <el-tooltip content="文档地址" effect="dark" placement="bottom">
                    <quick-admin-doc id="QuickAdmin-doc" class="right-menu-item hover-effect" />
                </el-tooltip>

                <el-tooltip content="源码地址" effect="dark" placement="bottom">
                    <quick-admin-git id="QuickAdmin-git" class="right-menu-item hover-effect" />
                </el-tooltip> -->

                <screenfull id="screenfull" class="right-menu-item hover-effect"/>
            </template>

            <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
                <div class="avatar-wrapper">
                    <img :src="avatar" class="user-avatar"/>
                    <el-tooltip class="item" effect="dark" :content="`所属角色： ${groups_name}`" placement="bottom">
                        <span class="text-mini"> {{ name || '管理员' }}</span>
                    </el-tooltip>
                    <i class="el-icon-caret-bottom"/>
                </div>
                <el-dropdown-menu slot="dropdown">
                    <router-link to="/staff/profile">
                        <el-dropdown-item>
                            <i class="el-icon-user"/>
                            <span>账户中心</span>
                        </el-dropdown-item>
                    </router-link>
                    <el-dropdown-item @click.native="setting = true">
                        <i class="el-icon-s-grid"/>
                        <span>布局设置</span>
                    </el-dropdown-item>
                    <el-dropdown-item divided @click.native="logout">
                        <i class="el-icon-switch-button"/>
                        <span>退出系统</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import TopNav from '@/components/TopNav'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch'
import QuickAdminGit from '@/components/QuickAdmin/Git'
import QuickAdminDoc from '@/components/QuickAdmin/Doc'
import {removeToken} from '@/utils/auth'

export default {
    components: {
        Breadcrumb,
        TopNav,
        Hamburger,
        Screenfull,
        SizeSelect,
        Search,
        QuickAdminGit,
        QuickAdminDoc,
    },
    computed: {
        ...mapGetters(['sidebar', 'avatar', 'device', 'groups_name', 'name']),
        setting: {
            get() {
                return this.$store.state.settings.showSettings
            },
            set(val) {
                this.$store.dispatch('settings/changeSetting', {
                    key: 'showSettings',
                    value: val,
                })
            },
        },
        topNav: {
            get() {
                return this.$store.state.settings.topNav
            },
        },
    },
    methods: {
        refreshSelectedTag() {
            this.$router.go()
        },
        toggleSideBar() {
            this.$store.dispatch('app/toggleSideBar')
        },
        async logout() {
            this.$confirm('确定注销并退出系统吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$store.dispatch('FedLogOut').then(() => {
                        location.href = `/login`
                    })
                })
                .catch(() => {
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.navbar {
    height: 50px;
    overflow: hidden;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

    .hamburger-container {
        line-height: 46px;
        height: 100%;
        float: left;
        cursor: pointer;
        transition: background 0.3s;
        -webkit-tap-highlight-color: transparent;

        &:hover {
            background: rgba(0, 0, 0, 0.025);
        }
    }

    .breadcrumb-container {
        float: left;
    }

    .topmenu-container {
        position: absolute;
        left: 50px;
    }

    .errLog-container {
        display: inline-block;
        vertical-align: top;
    }

    .right-menu {
        float: right;
        height: 100%;
        line-height: 50px;

        &:focus {
            outline: none;
        }

        .right-menu-item {
            display: inline-block;
            padding: 0 8px;
            height: 100%;
            font-size: 18px;
            color: #5a5e66;
            vertical-align: text-bottom;

            &.hover-effect {
                cursor: pointer;
                transition: background 0.3s;

                &:hover {
                    background: rgba(0, 0, 0, 0.025);
                }
            }
        }

        .avatar-container {
            margin-right: 30px;

            .avatar-wrapper {
                margin-top: 5px;
                position: relative;

                .user-avatar {
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    vertical-align: middle;
                }

                .el-icon-caret-bottom {
                    cursor: pointer;
                    position: absolute;
                    right: -20px;
                    top: 25px;
                    font-size: 12px;
                }
            }
        }
    }
}
</style>
